<template>
  <div class="section-wrapper">
    <b-breadcrumb>
    <b-breadcrumb-item to="/dashboard">
      <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
      Dashboard
    </b-breadcrumb-item>
    <b-breadcrumb-item to="/book-page">Book Page</b-breadcrumb-item>
    <b-breadcrumb-item active>Add New Content</b-breadcrumb-item>
  </b-breadcrumb>
    <b-card>
      <b-card-title>
        <b-row>
          <b-col>
            <h4 class="card-title mb-0 pl-0">Content Upload</h4>
          </b-col>
          <b-col class="mt-1">
             <p>Add Content on page - {{ pageNo }}</p>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="info" @click="previousRouter()"><i class="ri-arrow-left-line"></i>Back</b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
            <!-- <b-row>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Section" vid="section_id" rules="required|min_value:1">
                  <b-form-group
                      label-for="section_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      Select Section
                    </template>
                    <treeselect
                      id="section_id"
                      :options="sectionParentList"
                      placeholder="Select Section"
                      v-model="form.section_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      />
                    <template v-slot:first>
                      <b-form-select-option :id=0>Select</b-form-select-option>
                    </template>
                  <div class="d-block invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Page Content" vid="page_content" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="page_content"
                    slot-scope="{ valid, errors }"
                    >
                  <template v-slot:label>
                    Page Content
                  </template>
                  <vue-editor
                    id="text"
                    v-model="form.page_content"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></vue-editor>
                </b-form-group>
              </ValidationProvider>
              </b-col>
            </b-row> -->
            <!--Add more details start-->
            <span v-for="(info, index) in form.bookContents" :key="index">
              <b-row>
                <b-col lg="6" md="6" sm="6" xs="12">
                </b-col>
                <b-col lg="6" md="6" sm="6" xs="12" class="text-right">
                  <b-button v-if="form.bookContents.length > 1" variant="danger" size="sm" @click="deleteBookContent(info, index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" sm="12" xs="12">
                  <ValidationProvider name="Section" vid="`section_id$(index)`" rules="required|min_value:1">
                    <b-form-group
                      label-for="`section_id$(index)`"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        Select Section
                      </template>
                      <treeselect
                        id="section_id"
                        :options="sectionParentList"
                        placeholder="Select Section"
                        v-model="info.section_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        />
                      <template v-slot:first>
                        <b-form-select-option :id=0>Select</b-form-select-option>
                      </template>
                    <div class="d-block invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="12" md="12" sm="12" xs="12">
                  <div class="d-flex">
                  <div class="p-2">
                    <ValidationProvider name="Tags" vid="`tag_ids$(index)`">
                      <b-form-group
                        label-for="`tag_ids$(index)`"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          Select Tags
                        </template>
                        <treeselect
                          id="tag_ids"
                          :options="tagList"
                          placeholder="Select Tags"
                          v-model="info.tag_ids"
                          multiple
                          :state="errors[0] ? false : (valid ? true : null)"
                          />
                        <template v-slot:first>
                          <b-form-select-option :id=0>Select</b-form-select-option>
                        </template>
                      <div class="d-block invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="ml-auto p-2">
                    <b-form-checkbox
                      class="mt-4"
                      :id="'checkbox-'+index"
                      v-model="info.check_all_tags"
                      @change="checkAllTags(index)"
                    >
                     <strong class="text-nowrap">Select All</strong>
                    </b-form-checkbox>
                  </div>
                </div>
                </b-col>
                <b-col lg="12" md="12" sm="12" xs="12">
                  <ValidationProvider name="Page Content" vid="`page_content$(index)`" rules="required">
                    <b-form-group
                      label-cols-sm="12"
                      vid="`page_content$(index)`"
                      slot-scope="{ valid, errors }"
                      >
                    <template v-slot:label>
                      Page Content
                    </template>
                    <!-- <vue-editor
                      id="text"
                      v-model="info.page_content"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></vue-editor> -->
                    <!-- <quill-editor
                      style="height:300px;margin-bottom: 48px;"
                      v-model="info.page_content"
                      :state="errors[0] ? false : (valid ? true : null)"
                    /> -->
                    <vue2-tinymce-editor
                    id="page_content"
                    :width:="500" :height="300"
                    v-model="info.page_content"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :options="options"
                    >
                    </vue2-tinymce-editor>
                    <!-- <vue2-tinymce-editor :id="your-id" v-model="content" :width:="500" :height="300" :options="options"></vue2-tinymce-editor> -->
                    <div class="d-block invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
              </b-row>

              <b-row v-if="(form.bookContents.length - 1) == index">
                <b-col>
                  <template>
                    <div>
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="info.section_continuation"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="null"
                        @change="createContinueNexPage()"
                      >
                        Continue to Next Page
                      </b-form-checkbox>
                    </div>
                  </template>
                </b-col>
                <b-col class="text-right">
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">Complete & Save</b-button>
                  <b-button @click="addMoreBookContent(index)" v-if="form.section_continuation !== 1" variant="info" class="mr-2 btn-sm"><i class="ri-add-circle-line"></i> Add More Section</b-button>
                  <!-- <b-button @click="goToComp()" variant="danger" class="mr-2 btn-sm">Cancel</b-button> -->
                </b-col>
              </b-row>
            </span>
            <!--Add more details end-->
          </b-form>
       </ValidationObserver>
      </b-card-text>
    </b-card>
    <!-- <pre>{{  form }}</pre> -->
  </div>
</template>
<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
// import { VueEditor } from 'vue2-editor'
// import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import RestApi, { baseURL } from '@/config'
import { Vue2TinymceEditor } from 'vue2-tinymce-editor'
import axios from 'axios'

export default {
  name: 'Content',
  components: {
    Treeselect,
    Vue2TinymceEditor
  },
  data () {
    return {
      form: {
        bookContents: [
          {
            id: '',
            tag_ids: [],
            page_content: '',
            section_id: null,
            section_continuation: '',
            check_all_tags: false
          }
        ]
      },
      sectionParentList: [],
      tagList: [],
      value: null,
      pageNo: null,
      options: {
        // menubar: false,
        table_column_resizing: 'resizetable',
        // toolbar: 'bold italic underline | addcomment showcomments',
        // menubar: 'file edit view insert format tools tc',
        // plugins: 'paste code tinycomments',
        // menu: {
        //   tc: {
        //     title: 'Comments',
        //     items: 'addcomment showcomments deleteallconversations'
        //   }
        // },
        // plugins: 'image code editimage',
        // images_upload_url: 'postAcceptor.php'
        images_upload_handler: this.image_upload_handler
        // images_upload_handler: function (blobInfo, success, failure) {
        //   console.log('blobInfo', blobInfo)
        //   console.log('success', success)
        //   setTimeout(function () {
        //     success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png')
        //   }, 2000)
        // }
        // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        // toolbar1: 'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        // toolbar2: 'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code'
      }
    }
  },
  created () {
    this.getBookParentListDropDown()
    this.getTagList()
    this.pageNo = this.hash_id(this.$route.params.page_no, false)[0]
    // this.page_content_id = this.$route.params.page_content_id
    // this.page_content_id = this.hash_id(this.$route.params.page_content_id, false)[0]
    // if (this.page_content_id) {
    //   this.getBookEditContentList()
    // }
    // this.pageNo = this.$route.query.id
    // console.log('this.$route.params.id', this.$route.params.id)
    // this.this.hash_id(this.pageNo, false)
    // console.log('pageNo', this.hash_id(this.pageNo, false))
  },
  mounted () {
    if (this.hash_id(this.$route.params.page_no, false)[0]) {
      this.getBookContentByPageNo(this.pageNo)
    }
  },
  computed: {
  },
  methods: {
    checkAllTags: function (index) {
      // console.log('index', index)
      const bookContents = this.form.bookContents[index]
      // console.log('bookContents', bookContents)
      // console.log('bookContents.check_all_tags', bookContents.check_all_tags)
      // bookContents.check_all_tags = !bookContents.check_all_tags
      // console.log('bookContents.check_all_tags', bookContents.check_all_tags)

      if (bookContents.check_all_tags) {
        bookContents.tag_ids = []
        this.tagList.forEach(item => {
          bookContents.tag_ids.push(item.id)
        })
      } else {
        bookContents.tag_ids = []
      }
    },
    image_upload_handler: async function (blobInfo, success, failure) {
      console.log('blobInfo', blobInfo)
      console.log('success', success)
      console.log('failure', failure)

      const formData = new FormData()
      formData.append('photo', blobInfo.blob(), blobInfo.filename())
      formData.append('page_no', this.pageNo)

      const accessToken = localStorage.getItem('access_token')

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          accessMenuName: window.location.href,
          'Content-Type': 'multipart/form-data'
        }
      }

      const result = await axios.post(`${baseURL}api/v1/admin/ajax/upload_book_content_photo`, formData, axiosConfig)
      console.log('result', result)
      if (result.status === 200 && result.data.success === true) {
        success(result.data.location)
      } else {
        failure(result.data.message)
      }
      // return axios.post(`${baseURL}api/v1/admin/ajax/upload_book_content_photo`, postData, axiosConfig)
    },
    photoUploadUrl: function () {
      return this.base_url + '/api/v2/student/ajax/upload_student_profile_photo'
    },
    images_upload_url: function () {
      return RestApi.postData(baseURL, 'api/v1/admin/ajax/delete_book_content_data')
    },
    images_upload_handler: function (blobInfo, success, failure) {
      setTimeout(function () {
        success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png')
      }, 2000)
    },
    addMoreBookContent: function (index) {
      const bookContentObj = this.form.bookContents.find((item, localIndex) => localIndex === index)

      const tmpData = {
        id: '',
        tag_ids: [],
        page_content: '',
        section_id: bookContentObj ? bookContentObj.section_id : null,
        page_no_id: this.pageNo,
        section_continuation: ''
      }
      this.form.bookContents.push(tmpData)
    },
    deleteBookContent (item, index) {
      if (item.id) {
        this.$swal({
          title: 'Confirmation!',
          text: 'Are you sure to delete?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteBookContentConfirmed(item, index)
          }
        })
      } else {
        this.form.bookContents.splice(index, 1)
      }
    },
    deleteBookContentConfirmed: async function (item, index) {
      this.loading = true
      const result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/delete_book_content_data', item)
      if (result.success) {
        this.form.bookContents.splice(index, 1)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.getBookContentByPageNo(this.pageNo)
      } else {
        if (result.message) {
          this.$toast.danger({ title: 'Failed', message: result.message })
        }
        this.$refs.form.setErrors(result.errors)
      }
    },
    async createContinueNexPage () {
      this.loading = true
      let result = ''
      const lastIndexOfBookContent = this.form.bookContents.length - 1
      this.form.bookContents[lastIndexOfBookContent].section_continuation = 1
      const params = Object.assign({}, this.form, { page_no: this.pageNo })
      result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/store_continue_to_next_page_book_content_data', params)
      if (result.success) {
        this.$router.push(`/book-content-upload/${this.hash_id(result.new_created_page_no)}`)
        this.$router.go()
        // const router = this.$route.resolve(`/book-content-upload/${this.hash_id(result.new_created_page_no)}`)
        // window.location.href = router
        // if (result.newBookContent) {
        //   this.form.bookContents = []
        //   const tmpData = {
        //     id: result.newBookContent.id,
        //     section_id: result.newBookContent.section_id,
        //     section_continuation: '',
        //     page_no_id: result.newBookContent.new_created_page_no,
        //     page_content: result.newBookContent.page_content
        //   }
        //   this.form.bookContents.push(tmpData)
        // }
        if (result.new_created_page_no) {
          this.pageNo = result.new_created_page_no
        }
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async submitData () {
      this.loading = true
      let result = ''
      const params = Object.assign({}, this.form, { page_no: this.pageNo })
      console.log('params', params)
      result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/store_and_update_book_content_data', params)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        if (this.form.section_continuation === 1) {
          this.$bvModal.hide('modal-1')
          this.loading = false
        } else {
          this.$router.push('/book-page')
        }
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    previousRouter () {
      this.$router.push('/book-page')
    },
    goToComp () {
      this.$router.push('/book-page')
    },
    getTagList () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_tag_full_list', null).then(response => {
        if (response.success) {
          // this.sectionParentList = response.data
          this.tagList = response.data
        } else {
          this.tagList = []
        }
      })
    },
    getBookParentListDropDown () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_book_section_full_list', null).then(response => {
        if (response.success) {
          // this.sectionParentList = response.data
          this.sectionParentList = this.createNewDropdownList(response.data)
        } else {
          this.sectionParentList = []
        }
      })
    },
    createNewDropdownList (list, parentId = null) {
      const bookSectionList = []
      let mybookSectionList
      if (parentId === null) {
        mybookSectionList = list.filter(item => item.parent_id === undefined || item.parent_id === null)
      } else {
        mybookSectionList = list.filter(item => item.parent_id === parentId)
      }
      mybookSectionList.map(item => {
        bookSectionList.push({
          id: item.value,
          label: item.text,
          active: item.active,
          parent_id: item.parent_id,
          children: this.createNewDropdownList(list, item.value)
        })
      })
      return bookSectionList
    },
    async getBookContentByPageNo (pageNo) {
      const params = Object.assign({}, { page_no: pageNo })
      const result = await RestApi.getData(baseURL, 'api/v1/admin/ajax/get_book_contents_by_page_no', params)
      if (result.success) {
        if (result.data.length) {
          this.form.bookContents = result.data.map(item => {
            // const customizeItem = {
            //   id: item.id,
            //   page_content: item.page_content,
            //   section_id: item.section_id,
            //   page_no_id: item.page_no_id,
            //   section_continuation: ''
            // }
            item.section_continuation = ''
            item.check_all_tags = false
            return Object.assign(item)
          })
        }
      }
      console.log('this.form.bookContents', this.form.bookContents)
    }
  }
}
</script>
<style>
</style>
